import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./css/header.css";
import "./css/footer.css";
import { FaBars, FaTimes } from "react-icons/fa";
import LogoGrad from "./servicesData/justBrandify-logo-grad.png";
import LogoWhite from "./servicesData/justBrandify-logo-white.png";
import SocialMediaLinks from "./SocialMediaLinks";

function Header() {
  const [activeLink, setActiveLink] = useState("");
  const [toggleBars, settoggleBars] = useState(false);
  const [grad, setGrad] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const position = window.pageYOffset;
      if (position >= 100) {
        setGrad(true);
      } else {
        setGrad(false);
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleClick(link) {
    setActiveLink(link);
    toggle();
  }

  function toggle() {
    settoggleBars(!toggleBars);
  }

  return (
    <div className={grad ? "header grad" : "header"}>
      <div className="container">
        <Link to="/">
          {grad ? (
            <img className="header-logo" src={LogoGrad} alt="logo" />
          ) : (
            <img className="header-logo" src={LogoWhite} alt="logo" />
          )}
        </Link>
        <div className="header-right">
          {toggleBars ? (
            <FaTimes className="fa-bars" onClick={() => toggle()} />
          ) : (
            <FaBars className="fa-bars" onClick={() => toggle()} />
          )}
          <nav className={toggleBars ? "show" : "hide"}>
            <ul className="menu-list">
              <li className="menu-item">
                <Link
                  className={
                    activeLink === "home" ? "header-link active" : "header-link"
                  }
                  to="/"
                  onClick={() => handleClick("home")}
                >
                  HOME
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  className={
                    activeLink === "services"
                      ? "header-link active"
                      : "header-link"
                  }
                  to="/services"
                  onClick={() => handleClick("services")}
                >
                  OUR SERVICES
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  className={
                    activeLink === "portfolio"
                      ? "header-link active"
                      : "header-link"
                  }
                  to="/portfolio"
                  onClick={() => handleClick("portfolio")}
                >
                  PORTFOLIO
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  className={
                    activeLink === "about"
                      ? "header-link active"
                      : "header-link"
                  }
                  to="/about"
                  onClick={() => handleClick("about")}
                >
                  ABOUT US
                </Link>
              </li>
            </ul>
            <div className="header-social-links">
              <SocialMediaLinks />
            </div>
          </nav>

          <a
            className="header-btn"
            href="https://calendly.com/justbrandify/30min"
            target={"_blank"}
            rel="noreferrer"
          >
            BOOK A CONSULTATION
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
