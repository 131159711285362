import React, { useState } from "react";
import "./css/album.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Album = ({ data }) => {
  const [current, setCurrent] = useState(0);
  const Datalength = data.length;

  if (!Array.isArray(data) || data.length <= 0) {
    return null;
  }

  const moveLeft = () => {
    setCurrent(current === 0 ? Datalength - 1 : current - 1);
  };
  const moveRight = () => {
    setCurrent(current === Datalength - 1 ? 0 : current + 1);
  };

  function handleContextMenu(event) {
    event.preventDefault();
  }

  return (
    <section className="slider">
      <div className="arr-left" onClick={moveLeft}>
        <FaAngleLeft />
      </div>

      {data.map((image, index) => {
        return (
          <div
            key={index}
            className={current === index ? "active-slide" : "slide"}
          >
            {index === current && (
              <div className="image-container">
                <img src={image} alt="none" onContextMenu={handleContextMenu} />
                <div className="overlay"></div>
              </div>
            )}
          </div>
        );
      })}
      <div className="arr-right" onClick={moveRight}>
        <FaAngleRight />
      </div>
    </section>
  );
};

export default Album;
