import React, { useEffect } from "react";
import PortfolioHero from "./PortfolioHero";
import CaseStudies from "./CaseStudies";
import VideoPlayer from "./VideoPlayer";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <PortfolioHero />
      <CaseStudies />
      <VideoPlayer
        videoIds={[
          "gsclN0u6ktw",
          "pZ8BPfinL5E",
          "V9_xFFTdI34",
          "cefuy-IVeQM",
          "X38RiKKu-5A",
          "DDpbrp7mw8M",
          "jwrecS1dduQ",
        ]}
      />
    </div>
  );
};

export default Portfolio;
