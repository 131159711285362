import React, { useEffect } from "react";
import Hero from "./Hero";
import Expertise from "./Expertise";
import UpliftBrand from "./UpliftBrand";
import BestSellerBrand from "./BestSellerBrand";
import Review from "./Review";
import Calendly from "./Calendly";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <Hero />
      <Expertise />
      <UpliftBrand />
      <Calendly />
      <Review />
      <BestSellerBrand />
    </div>
  );
};

export default Home;
