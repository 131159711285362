import React, { useState, useEffect } from "react";
import "./css/form.css";
import { FaTimes } from "react-icons/fa";
import emailjs from "@emailjs/browser";

const Form = ({ text, hide }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (success === true) {
        setSuccess(false);
        hide(false);
        setEmail("");
        setMessage("");
        setName("");
        setPhoneNo("");
      } else if (failure === true) {
        setFailure(false);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [success, failure]);

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_q07ngjo",
        "template_jepwcte",
        event.target,
        "FIP27AQhEDkbPSG3n"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccess(true);
        },
        (error) => {
          console.log(error.text);
          setFailure(true);
        }
      );
  };

  const closeForm = () => {
    hide(false);
  };

  return (
    <div className="booking">
      {success && (
        <p className="alert success">Response submitted successfully</p>
      )}
      {failure && <p className="alert failure">Failed to send response</p>}
      <div className="form-container">
        <FaTimes className="close" onClick={closeForm} />
        <form className="booking-form" action="post" onSubmit={handleSubmit}>
          <h3>{text}</h3>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            id="name"
            placeholder="Name"
            required
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            id="email"
            placeholder="Email"
            required
          />
          <input
            type="tel"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
            name="phoneNo"
            id="phoneNo"
            placeholder="Phone no"
          />
          <input
            type="text"
            value={text}
            name="category"
            id="category"
            placeholder="Category"
          />
          <textarea
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            id="message"
            cols="30"
            rows="10"
            placeholder="Message"
          />
          <button className="submit-btn" type="submit">
            <h4>SUBMIT</h4>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
