const sliderData1 = [
  {
    product: "YorkZone",
    headline:
      "Transform Your Ecommerce Business Like YorkZone Did with JustBrandify: Achieved 250% Increase in Traffic and 300% in Sales on Amazon Marketplace!",
    summary:
      "JustBrandify helped YorkZone, an ecommerce brand specializing in fitness water bottles, build their brand from scratch and launch successfully on Amazon marketplace, resulting in a 250% increase in website traffic and a 300% increase in sales. Just Brandify's comprehensive ecommerce solution included product sourcing, website design and development, brand identity development, digital marketing campaigns, logistics and supply chain optimization, and Amazon FBA.",
    statement: "“We couldn't be happier with the work done by JustBrandify.”",
    founder: " -Founder YorkZone",
    images: [
      "https://i.ibb.co/VV6CkTZ/1.jpg",
      "https://i.ibb.co/YPf71zG/2.jpg",
      "https://i.ibb.co/rGW7NJn/3.jpg",
      "https://i.ibb.co/vqZW6VY/4.jpg",
      "https://i.ibb.co/WfBh7Pc/5.jpg",
      "https://i.ibb.co/vBtszQ9/6.jpg",
      "https://i.ibb.co/dfSFvzV/7.jpg",
      "https://i.ibb.co/jgZFGcP/8.jpg",
      "https://i.ibb.co/tKzdcf3/9.jpg",
      "https://i.ibb.co/8rhrDgw/10.png",
      "https://i.ibb.co/2F1W41T/11.png",
      "https://i.ibb.co/5Gbhmdz/12.jpg",
      "https://i.ibb.co/GQJZzK5/13.jpg",
    ],
  },
  {
    product: "Kitchener",
    headline:
      "Kitchener Achieves 200% Sales Growth with ROI in Just 2 Months with JustBrandify's Ecommerce Expertise!",
    summary:
      "Kitchener, an ecommerce brand specializing in home kitchen appliances, approached JustBrandify to build their brand from scratch, including Product selection, website development, branding, marketing campaigns, and logistics. Through market analysis, digital marketing campaigns, and supply chain optimization, JustBrandify helped Kitchener achieve a 200% increase in sales, 35% net profit margins, and ROI within just 2 months.",
    statement:
      "“Their expertise and commitment to quality in ecommerce is unmatched.”",
    founder: " –Founder Kitchener",
    images: [
      "https://i.ibb.co/hLxnNGy/image.png",
      "https://i.ibb.co/1d0bXDT/1.png",
      "https://i.ibb.co/pwXcW7R/2.png",
      "https://i.ibb.co/NnfSmcc/3.png",
      "https://i.ibb.co/q1WLz0g/4.png",
      "https://i.ibb.co/3cjNvJ7/5.png",
      "https://i.ibb.co/VTqr9jj/6.png",
      "https://i.ibb.co/wLh5ZwC/7.png",
      "https://i.ibb.co/pJ0QQ12/8.png",
      "https://i.ibb.co/1JScWBF/9.png",
      "https://i.ibb.co/QPzvdcm/10.png",
      "https://i.ibb.co/rm3WJ8F/11.png",
      "https://i.ibb.co/Gv8bCsw/12.png",
      "https://i.ibb.co/N7Yv7Z3/13.jpg",
      "https://i.ibb.co/QHC0LHY/14.png",
      "https://i.ibb.co/TYMvzMZ/15.png",
    ],
  },
  {
    product: "BEDROCK",
    headline:
      "How JustBrandify Helped BEDROCK Increase Their Sales by 40% and Boost Their Online Presence",
    summary:
      "BEDROCK, a leading tire provider for various vehicles, sought the help of JustBrandify to expand their online presence and customer base. Through a comprehensive digital marketing strategy, JustBrandify was able to increase website traffic by 50%, boost engagement on social media by 75%, and grow sales by 40%.",
    statement: "",
    founder: "",
    images: [
      "https://i.ibb.co/9gs0JFb/1.jpg",
      "https://i.ibb.co/VtBZbD3/2.jpg",
      "https://i.ibb.co/R9JgRdC/3.png",
      "https://i.ibb.co/N7YT9V0/4.png",
      "https://i.ibb.co/tBwpwZy/5.png",
      "https://i.ibb.co/QJpqjn6/6.jpg",
      "https://i.ibb.co/wCL9pVs/7.png",
      "https://i.ibb.co/Zc6bTkF/8.png",
      "https://i.ibb.co/YZWSby9/9.png",
      "https://i.ibb.co/CbtPvG5/10.png",
      "https://i.ibb.co/KG7csDd/11.png",
    ],
  },
  {
    product: "PANACHE",
    headline:
      "Experience the Ultimate Luxury with PANACHE - A Case Study on How JustBrandify Helped Elevate a High-End Brand",
    summary:
      "PANACHE is a luxury brand that offers exclusive items such as jewelry, watches, couture, and accessories. The brand targets the elite class, and they came to JustBrandify for help with brand positioning and design.",
    statement: "",
    founder: "",
    images: [
      "https://i.ibb.co/fqpSX2C/1.png",
      "https://i.ibb.co/HGBxm6Y/2.png",
      "https://i.ibb.co/hsTBNHp/3.png",
      "https://i.ibb.co/dmG4zts/4.png",
      "https://i.ibb.co/vVLtJq8/5.png",
      "https://i.ibb.co/G7cxSkM/6.png",
      "https://i.ibb.co/tzgwF50/7.jpg",
      "https://i.ibb.co/jh3KfB4/8.png",
      "https://i.ibb.co/nBQ7b1b/9.png",
    ],
  },
  {
    product: "PODD",
    headline:
      "Revving Up Sales: How JustBrandify Helped Podd Batteries Drive Business Growth with Strategic Branding and Marketing",
    summary:
      "Podd is a brand that specializes in providing high-quality batteries for a range of vehicles, including cars, trucks, and bikes. As a new player in a highly competitive industry, they sought our help in developing a strong brand positioning and implementing effective marketing strategies to attract and retain customers.",
    statement: "",
    founder: "",
    images: [
      "https://i.ibb.co/zVLhy9s/1.png",
      "https://i.ibb.co/ZxMs16V/2.jpg",
      "https://i.ibb.co/WvJSYtt/3.png",
      "https://i.ibb.co/3vcBNFJ/4.jpg",
      "https://i.ibb.co/nLmHxKB/5.jpg",
      "https://i.ibb.co/pnydhQT/6.jpg",
      "https://i.ibb.co/VxChs0j/7.jpg",
      "https://i.ibb.co/nLvQDHC/8.jpg",
      "https://i.ibb.co/PCHTZFW/9.jpg",
    ],
  },
  {
    product: "Design and Branding",
    headline: "",
    summary: "",
    statement: "",
    founder: "",
    images: [
      "https://i.ibb.co/9nJWgq3/5.jpg",
      "https://i.ibb.co/Nnt0wdn/4.jpg",
      "https://i.ibb.co/zGZgX3y/6.jpg",
      "https://i.ibb.co/0Bqjkhp/7.jpg",
      "https://i.ibb.co/dfmkSvH/8.jpg",
      "https://i.ibb.co/LPrJ35X/9.png",
      "https://i.ibb.co/rGG44Rr/10.png",
      "https://i.ibb.co/pPFHbbw/11.png",
      "https://i.ibb.co/T4kY8kb/12.png",
      "https://i.ibb.co/93s20dx/13.png",
      "https://i.ibb.co/Jmygp8L/14.png",
    ],
  },
  {
    product: "Product Photography",
    headline: "",
    summary: "",
    statement: "",
    founder: "",
    images: [
      "https://i.ibb.co/w4VZjL7/3.jpg",
      "https://i.ibb.co/3pJg2tt/1.jpg",
      "https://i.ibb.co/wsvZk94/2.jpg",
      "https://i.ibb.co/m8RjB02/4.jpg",
      "https://i.ibb.co/7r36dbN/5.jpg",
      "https://i.ibb.co/DgK9K0f/6.jpg",
      "https://i.ibb.co/9g6j2WV/7.jpg",
      "https://i.ibb.co/d5sRxYX/8.jpg",
      "https://i.ibb.co/cDqvWTr/9.jpg",
      "https://i.ibb.co/7Gpj9dz/10.jpg",
      "https://i.ibb.co/8zW2PqQ/11.jpg",
      "https://i.ibb.co/XC0CZTV/12.jpg",
      "https://i.ibb.co/p4XFscv/13.jpg",
      "https://i.ibb.co/SfB9GCY/14.jpg",
      "https://i.ibb.co/3RGtgCz/15.png",
      "https://i.ibb.co/TBLsZ4x/16.png",
      "https://i.ibb.co/Vpd0kgB/17.png",
      "https://i.ibb.co/rkTS14h/18.png",
    ],
  },
];

export default sliderData1;
