import React, { useEffect, useRef } from "react";
import "./about.css";

const About = () => {
  const sectionRefs = useRef([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
        } else {
          entry.target.classList.remove("fade-in");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    sectionRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="about-hero">
      <svg
        width="700"
        height="700"
        viewBox="0 0 588 588"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="about-hero-bg"
      >
        <path
          d="M293.927 577.016C450.357 577.016 577.169 450.204 577.169 293.774C577.169 137.343 450.357 10.5315 293.927 10.5315C137.496 10.5315 10.6846 137.343 10.6846 293.774C10.6846 450.204 137.496 577.016 293.927 577.016Z"
          stroke="#4924CE"
          stroke-width="20"
          stroke-miterlimit="10"
        />
        <path
          d="M440.309 405.121H370.366C366.258 348.651 348.836 301.704 325.267 279.728C332.847 274.504 339.026 267.37 343.071 259.025C349.504 261.796 356.607 263.325 364.092 263.325C375.303 263.325 385.718 259.853 394.286 253.961C421.358 279.186 440.309 337.376 440.309 405.121Z"
          fill="#4924CE"
        />
        <path
          d="M416.071 209.976C416.071 227.717 407.153 243.387 393.553 252.751C385.208 258.516 375.048 261.892 364.156 261.892C356.894 261.892 349.982 260.395 343.708 257.719C345.874 253.26 347.402 248.451 348.231 243.419C348.676 240.711 348.899 237.941 348.899 235.106C348.899 213.703 335.936 195.325 317.432 187.395C325.84 170.068 343.612 158.093 364.187 158.093C392.821 158.061 416.071 181.311 416.071 209.976Z"
          fill="#4924CE"
        />
        <path
          d="M264.879 279.696C241.31 301.672 223.888 348.619 219.78 405.089H147.544C147.544 337.344 166.495 279.154 193.567 253.897C202.135 259.821 212.55 263.261 223.761 263.261C231.946 263.261 239.718 261.414 246.629 258.102C250.706 266.86 257.012 274.313 264.879 279.696Z"
          fill="#4924CE"
        />
        <path
          d="M241.215 235.074C241.215 238.323 241.502 241.54 242.075 244.629C242.871 248.929 244.209 253.037 245.993 256.891C239.241 260.108 231.692 261.892 223.698 261.892C212.773 261.892 202.645 258.516 194.3 252.751C180.7 243.387 171.782 227.717 171.782 209.976C171.782 181.311 195.033 158.061 223.698 158.061C244.591 158.061 262.587 170.387 270.836 188.191C253.318 196.536 241.215 214.403 241.215 235.074Z"
          fill="#4924CE"
        />
        <path
          d="M369.57 429.2H220.576C220.576 420.632 220.895 412.224 221.5 404.006C225.513 348.81 242.553 302.914 265.58 281.448C269.02 283.804 272.746 285.779 276.696 287.276C282.429 289.442 288.608 290.62 295.105 290.62C300.583 290.62 305.87 289.76 310.839 288.2C315.808 286.639 320.426 284.346 324.63 281.448C347.658 302.946 364.697 348.81 368.711 404.006C369.252 412.192 369.57 420.6 369.57 429.2Z"
          fill="#4924CE"
        />
        <path
          d="M346.925 235.775C346.925 238.609 346.702 241.38 346.256 244.088C345.46 249.12 343.899 253.929 341.765 258.388C337.816 266.51 331.828 273.453 324.439 278.517C320.266 281.384 315.648 283.677 310.711 285.238C305.775 286.798 300.519 287.658 295.041 287.658C288.576 287.658 282.429 286.48 276.728 284.314C272.778 282.817 269.083 280.874 265.676 278.517C258.032 273.262 251.885 266.032 247.967 257.56C246.183 253.706 244.877 249.598 244.049 245.33C243.476 242.24 243.189 239.055 243.189 235.775C243.189 215.104 255.292 197.236 272.81 188.923C279.562 185.706 287.111 183.923 295.073 183.923C302.335 183.923 309.246 185.42 315.521 188.095C333.994 196.026 346.925 214.372 346.925 235.775Z"
          fill="#4924CE"
        />
      </svg>

      <div className="about-hero-container">
        <section
          className="section left"
          ref={(el) => (sectionRefs.current[0] = el)}
        >
          <div className="about-hero-left">
            <div className="rectangle"></div>
          </div>
          <div className="about-hero-right">
            <h1>About us</h1>
            <p>
              At Just Brandify, our sole focus is to elevate your brand identity
              and help you succeed in the competitive marketplace. Our team
              leverages marketing and behavioral science to design, digitize,
              and grow your business. We specialize in crafting unique and
              impactful branding strategies, executing creative campaigns, and
              developing technology solutions to help you stand out and cut
              through the digital noise. Our services encompass experience
              design, brand identity, product strategy, branding, technology
              development, digital marketing, and SEO, with a focus on the
              Amazon and Shopify platforms. Our goal is to simplify the complex
              world of digital marketing and provide a comprehensive suite of
              services to guide our clients towards digital enlightenment and
              success.
            </p>
          </div>
        </section>
        <section
          className="section right"
          ref={(el) => (sectionRefs.current[1] = el)}
        >
          <div className="about-hero-left">
            <div className="rectangle"></div>
          </div>
          <div className="about-hero-right">
            <h1>Core Values</h1>
            <p>
              Our core values are centered around delivering excellence for our
              clients' brands. We approach every project with passion and
              creativity, guided by the principle of always doing what is right
              for the brand. As a team rooted in digital, we constantly strive
              for innovation and push boundaries to deliver outstanding results.
            </p>
          </div>
        </section>
        <section
          className="Section left"
          ref={(el) => (sectionRefs.current[2] = el)}
        >
          <div className="about-hero-left">
            <div className="rectangle"></div>
          </div>
          <div className="about-hero-right">
            <h1>Mission statment</h1>
            <p>
              Our goal is to elevate your brand to new heights through a
              strategic blend of visual media and targeted digital marketing
              efforts, resulting in a stronger brand experience, increased brand
              recognition and improved brand recall for both consumers and
              stakeholders.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
