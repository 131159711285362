import React from "react";
import "./css/portfolioHero.css";

const ServiceHero = () => {
  return (
    <div className="portfolio-hero">
      <svg
        width="700"
        height="700"
        viewBox="0 0 605 604"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="portfolio-hero-bg"
      >
        <path
          d="M302.5 593.972C463.563 593.972 594.18 463.355 594.18 302.292C594.18 141.229 463.563 10.6124 302.5 10.6124C141.437 10.6124 10.8203 141.229 10.8203 302.292C10.9242 463.355 141.437 593.972 302.5 593.972Z"
          stroke="#4924CE"
          stroke-width="20"
          stroke-miterlimit="10"
        />
        <path
          d="M457.328 245.349V390.305H147.672V245.349C165.96 279.224 221.345 304.578 288.472 307.696V314.45C288.472 321.308 294.083 326.919 300.941 326.919H304.059C310.917 326.919 316.528 321.308 316.528 314.45V307.696C383.759 304.578 439.04 279.224 457.328 245.349Z"
          fill="#4924CE"
        />
        <path
          d="M457.328 395.501V416.283C457.328 423.141 451.717 428.753 444.859 428.753H160.141C153.283 428.753 147.672 423.141 147.672 416.283V395.501H457.328Z"
          fill="#4924CE"
        />
        <path
          d="M457.328 220.929V239.114C439.04 272.989 383.655 298.344 316.424 301.461C315.593 295.33 310.397 290.55 304.059 290.55H300.941C294.603 290.55 289.407 295.33 288.576 301.461C221.449 298.344 165.96 272.989 147.672 239.114V220.929C147.672 214.071 153.283 208.46 160.141 208.46H444.859C451.821 208.46 457.328 214.071 457.328 220.929Z"
          fill="#4924CE"
        />
        <path
          d="M339.077 188.301V205.343H265.923V188.301C265.923 181.443 271.534 175.832 278.392 175.832H326.607C333.465 175.832 339.077 181.443 339.077 188.301Z"
          fill="#4924CE"
        />
      </svg>

      <div className="portfolio-hero-container">
        <div className="portfolio-hero-left">
          <h1>Portfolio</h1>
          <br />
          <p></p>
          <svg
            width="24"
            height="72"
            viewBox="0 0 24 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="down-arrow"
          >
            <path
              d="M10.9393 70.9657C11.5251 71.5515 12.4749 71.5515 13.0607 70.9657L22.6066 61.4197C23.1924 60.834 23.1924 59.8842 22.6066 59.2984C22.0208 58.7126 21.0711 58.7126 20.4853 59.2984L12 67.7837L3.51472 59.2984C2.92893 58.7126 1.97919 58.7126 1.3934 59.2984C0.807614 59.8842 0.807614 60.834 1.3934 61.4197L10.9393 70.9657ZM10.5 6.54781e-08L10.5 69.905L13.5 69.905L13.5 -6.54781e-08L10.5 6.54781e-08Z"
              fill="#FFD9D9"
            />
          </svg>
        </div>
        <div className="portfolio-hero-right"></div>
      </div>
    </div>
  );
};

export default ServiceHero;
