import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./css/review.css";
const Review = () => {
  const [count, setCount] = useState(0);

  const increment = () => {
    if (count === 1) setCount(0);
    else setCount(count + 1);
  };

  const decrement = () => {
    if (count === 0) setCount(1);
    else setCount(count - 1);
  };

  let reviews = [
    {
      review:
        "Working with JustBrandify has been a game-changer for our business. Their attention to detail, commitment to quality, and expertise in ecommerce is unmatched.",
      owner: "Abdullah (CEO Kitchener)",
    },
    {
      review:
        "JustBrandify team helped us build our brand from scratch and launched successfully on Amazon marketplace, driving significant sales and pure profits in a short period of time. Their expertise in ecommerce and commitment to quality is truly impressive. We highly recommend their services to anyone looking to build a successful ecommerce business.",
      owner: "Faisal (CEO YorkZone)",
    },
  ];
  return (
    <div className="reviews">
      <div className="reviews-container">
        <div className="owner-statment">
          <p>
            “A well-managed brand ultimately represents a well-defined corporate
            identity – one that is integral to the strategic outlook of your
            business”
          </p>
          <h4>Ahmad(CEO JustBrandify)</h4>
        </div>
        <div className="client-reviews">
          <FaAngleLeft className="arrow" onClick={increment} />
          {reviews.map((rev, index) => {
            if (index === count) {
              return (
                <div key={index} className="review">
                  <p>{rev.review}</p>
                  <h4>{rev.owner}</h4>
                </div>
              );
            }
          })}
          <FaAngleRight className="arrow" onClick={decrement} />
        </div>
      </div>
    </div>
  );
};

export default Review;
