import React, { useState, useEffect } from "react";
import "./css/footer.css";
import emailjs from "@emailjs/browser";
import SocialMediaLinks from "./SocialMediaLinks";

import LogoWhite from "./servicesData/justBrandify-logo-white.png";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (success === true) {
        setSuccess(false);
        setEmail("");
        setMessage("");
        setName("");
      } else if (failure === true) {
        setFailure(false);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [success, failure]);

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_q07ngjo",
        "template_jepwcte",
        event.target,
        "FIP27AQhEDkbPSG3n"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccess(true);
        },
        (error) => {
          console.log(error.text);
          setFailure(true);
        }
      );
  };

  return (
    <div className="footer">
      {success && (
        <p className="alert success">Response submitted successfully</p>
      )}
      {failure && <p className="alert failure">Failed to send response</p>}
      <div className="footer-container">
        <div className="left-links">
          <div className="footer-logo">
            <img src={LogoWhite} alt="logo" />
          </div>
          <div className="links">
            <div className="link">
              <h3>CONTACT US</h3>
              <ul>
                <li>+92 320 8468081</li>
                <li>+1 (929) 425-2497</li>
                <li>contact@justbrandify.com</li>
              </ul>
            </div>
            <div className="link">
              <h3>STUDIO</h3>
              <ul>
                <li>Gulberg, Lahore, Pakistan</li>
              </ul>
            </div>
          </div>
          <SocialMediaLinks />
        </div>
        <div className="right-form">
          <form action="post" onSubmit={handleSubmit}>
            <h3>WORK WITH US</h3>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              id="name"
              placeholder="Name"
              required
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              id="email"
              placeholder="Email"
              required
            />
            <textarea
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              id="message"
              cols="30"
              rows="10"
              placeholder="Message"
            ></textarea>
            <button className="submit-btn" type="submit">
              <h4>SUBMIT</h4>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Footer;
