import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Portfolio from "./components/Portfolio/Portfolio";
import Footer from "./components/Footer";
import { FaWhatsapp } from "react-icons/fa";
import ReactWhatsapp from "react-whatsapp";

function App() {
  const [displayText, setDisplayText] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayText((prevState) => !prevState);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="app">
      <ReactWhatsapp number="+19294252497" message="" className="whatsapp-link">
        {displayText && <p>Chat with us</p>}
        <FaWhatsapp className="whatsapp-icon" />
      </ReactWhatsapp>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
