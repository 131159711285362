import React, { useRef, useEffect } from "react";
import Album from "./Album";
import data1 from "./utility/SliderData1";
import "./css/portfolio.css";
import "../css/generalElement.css";

const CaseStudies = () => {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
        } else {
          entry.target.classList.remove("fade-in");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    sectionRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <div className="portfolio">
        <div className="portfolio-container">
          {data1.map((data, index) => {
            return (
              <div
                key={index}
                className="portfolio-item"
                ref={(el) => (sectionRefs.current[index] = el)}
              >
                <div className="item-container">
                  <div className="left-content">
                    <h1 className="product">{data.product}</h1>
                    <h4 className="headline">{data.headline}</h4>
                    <p className="summary">{data.summary}</p>
                    <p className="statement">{data.statement}</p>
                    <p className="founder">{data.founder}</p>
                  </div>
                  <div className="right-album">
                    <Album data={data.images} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
