import React, { useRef, useEffect } from "react";
import "./css/expertise.css";
import "../css/generalElement.css";

const Expertise = () => {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("bg");
        } else {
          entry.target.classList.remove("bg");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    sectionRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="expertise">
      <h1>Area of Expertise</h1>
      <div className="expertise-item">
        <div className="left">
          <h2>Strategic Branding</h2>
          <p>
            We believe that creating a strong brand identity is more than just
            outspending your competitors. It’s about crafting a unique brand
            persona that sets you apart from the rest. Our team of branding
            experts can help you repackage and reposition your brand in a way
            that aligns with your long-term vision and strategic goals.
          </p>
        </div>
        <div className="right">
          <div className="" ref={(el) => (sectionRefs.current[0] = el)}></div>
          <svg
            width="267"
            height="268"
            viewBox="0 0 267 268"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="brand-development"
          >
            <path
              d="M133.27 267.817C206.579 267.817 266.008 207.905 266.008 134C266.008 60.0951 206.579 0.18335 133.27 0.18335C59.9613 0.18335 0.532715 60.0951 0.532715 134C0.532715 207.905 59.9613 267.817 133.27 267.817Z"
              fill="url(#paint0_linear_249_8)"
            />
            <path
              d="M91.7918 146.882C91.5868 147.261 91.2793 147.571 90.8693 147.812C90.4593 148.053 90.0151 148.157 89.4684 148.157H87.3501V143.024H89.4684C90.0151 143.024 90.4593 143.128 90.8693 143.334C91.2793 143.541 91.5868 143.851 91.7918 144.23C91.9968 144.609 92.0993 145.057 92.0993 145.539C92.0993 146.056 91.9968 146.503 91.7918 146.882ZM90.1518 146.434C90.3568 146.228 90.4593 145.952 90.4593 145.573C90.4593 145.194 90.3568 144.919 90.1518 144.712C89.9468 144.506 89.6734 144.402 89.2976 144.402H88.9218V146.676H89.2976C89.6734 146.71 89.9468 146.607 90.1518 146.434Z"
              fill="#5928E5"
            />
            <path
              d="M98.0101 144.299V144.919H99.5818V146.124H98.0101V146.848H99.7868V148.122H96.4043V142.99H99.7868V144.264H98.0101V144.299Z"
              fill="#5928E5"
            />
            <path
              d="M105.493 143.024L106.552 146.538L107.577 143.024H109.285L107.577 148.157H105.527L103.818 143.024H105.493Z"
              fill="#5928E5"
            />
            <path
              d="M114.991 144.299V144.919H116.563V146.124H114.991V146.848H116.768V148.122H113.385V142.99H116.768V144.264H114.991V144.299Z"
              fill="#5928E5"
            />
            <path
              d="M122.713 146.951H124.25V148.157H121.107V143.024H122.713V146.951Z"
              fill="#5928E5"
            />
            <path
              d="M132.313 143.266C132.723 143.507 133.031 143.817 133.27 144.196C133.509 144.574 133.612 145.057 133.612 145.539C133.612 146.021 133.509 146.503 133.27 146.882C133.031 147.296 132.723 147.606 132.313 147.812C131.903 148.019 131.493 148.157 131.015 148.157C130.537 148.157 130.093 148.053 129.683 147.812C129.273 147.571 128.965 147.261 128.726 146.882C128.487 146.469 128.384 146.021 128.384 145.539C128.384 145.057 128.487 144.574 128.726 144.196C128.965 143.782 129.273 143.472 129.683 143.266C130.093 143.059 130.537 142.921 131.015 142.921C131.493 142.921 131.903 143.059 132.313 143.266ZM130.263 144.747C130.093 144.953 129.99 145.229 129.99 145.573C129.99 145.918 130.093 146.193 130.263 146.4C130.434 146.607 130.673 146.71 131.015 146.71C131.323 146.71 131.562 146.607 131.733 146.4C131.903 146.193 132.006 145.918 132.006 145.573C132.006 145.229 131.903 144.953 131.733 144.747C131.562 144.54 131.323 144.437 131.015 144.437C130.673 144.437 130.434 144.54 130.263 144.747Z"
              fill="#5928E5"
            />
            <path
              d="M139.523 146.538V148.157H137.917V143.024H140.138C140.753 143.024 141.231 143.197 141.573 143.507C141.914 143.817 142.085 144.264 142.085 144.781C142.085 145.091 142.017 145.401 141.846 145.677C141.709 145.952 141.47 146.159 141.197 146.297C140.924 146.434 140.548 146.538 140.138 146.538H139.523ZM140.479 144.781C140.479 144.471 140.309 144.299 140.001 144.299H139.557V145.229H140.001C140.309 145.263 140.479 145.091 140.479 144.781Z"
              fill="#5928E5"
            />
            <path
              d="M152.438 143.024V148.157H150.866V145.608L150.08 148.157H148.713L147.928 145.608V148.157H146.322V143.024H148.269L149.397 146.09L150.49 143.024H152.438Z"
              fill="#5928E5"
            />
            <path
              d="M158.519 144.299V144.919H160.091V146.124H158.519V146.848H160.296V148.122H156.914V142.99H160.296V144.264H158.519V144.299Z"
              fill="#5928E5"
            />
            <path
              d="M169.521 148.157H167.915L166.241 145.573V148.157H164.635V143.024H166.241L167.915 145.642V143.024H169.521V148.157Z"
              fill="#5928E5"
            />
            <path
              d="M177.994 143.024V144.299H176.662V148.157H175.056V144.299H173.724V143.024H177.994Z"
              fill="#5928E5"
            />
            <path
              d="M100.641 133.828C101.221 134.586 101.529 135.481 101.529 136.48C101.529 138.03 101.016 139.201 100.026 140.028C99.0348 140.855 97.5656 141.268 95.6865 141.268H86.564V123.701H95.4473C97.224 123.701 98.6248 124.08 99.6156 124.872C100.641 125.665 101.153 126.767 101.153 128.282C101.153 129.316 100.88 130.177 100.367 130.866C99.8206 131.555 99.1373 132.037 98.249 132.278C99.2398 132.553 100.06 133.07 100.641 133.828ZM91.9965 130.59H94.1148C94.5931 130.59 94.969 130.487 95.2081 130.28C95.4473 130.073 95.5498 129.763 95.5498 129.35C95.5498 128.937 95.4473 128.592 95.2081 128.386C94.969 128.179 94.5931 128.076 94.1148 128.076H91.9965V130.59ZM95.584 136.618C95.8231 136.411 95.9256 136.101 95.9256 135.688C95.9256 134.861 95.4473 134.413 94.4906 134.413H91.9965V136.893H94.4906C94.969 136.893 95.3448 136.79 95.584 136.618Z"
              fill="#5928E5"
            />
            <path
              d="M113.795 141.302L110.515 134.999V141.302H105.048V123.736H113.18C114.581 123.736 115.811 123.977 116.802 124.493C117.793 125.01 118.544 125.699 119.057 126.56C119.569 127.421 119.808 128.42 119.808 129.488C119.808 130.659 119.501 131.692 118.852 132.622C118.203 133.518 117.28 134.172 116.05 134.586L119.843 141.302H113.795ZM110.515 131.348H112.668C113.18 131.348 113.59 131.244 113.863 131.003C114.137 130.762 114.273 130.383 114.273 129.867C114.273 129.419 114.137 129.04 113.863 128.764C113.59 128.489 113.214 128.351 112.702 128.351H110.549V131.348H110.515Z"
              fill="#5928E5"
            />
            <path
              d="M134.397 138.65H128.589L127.735 141.302H121.995L128.35 123.736H134.636L140.991 141.302H135.251L134.397 138.65ZM133.065 134.517L131.493 129.626L129.921 134.517H133.065Z"
              fill="#5928E5"
            />
            <path
              d="M160.637 141.302H155.171L149.396 132.484V141.302H143.93V123.736H149.396L155.171 132.726V123.736H160.637V141.302Z"
              fill="#5928E5"
            />
            <path
              d="M180.01 136.997C179.258 138.34 178.233 139.373 176.832 140.166C175.466 140.923 173.86 141.302 172.049 141.302H164.806V123.736H172.049C173.86 123.736 175.466 124.114 176.832 124.838C178.199 125.596 179.258 126.629 180.01 127.938C180.761 129.247 181.103 130.762 181.103 132.45C181.103 134.138 180.761 135.653 180.01 136.997ZM174.509 135.378C175.226 134.689 175.602 133.724 175.602 132.484C175.602 131.244 175.226 130.246 174.509 129.591C173.791 128.902 172.801 128.558 171.571 128.558H170.272V136.411H171.571C172.801 136.411 173.791 136.067 174.509 135.378Z"
              fill="#5928E5"
            />
            <path
              d="M131.972 213.98C175.787 213.98 211.307 178.172 211.307 134C211.307 89.8283 175.787 54.02 131.972 54.02C88.1562 54.02 52.6367 89.8283 52.6367 134C52.6367 178.172 88.1562 213.98 131.972 213.98Z"
              stroke="#5928E5"
              stroke-miterlimit="10"
              stroke-dasharray="9.99 9.99"
            />
            <path
              d="M152.984 41.8955H112.975V69.2099H152.984V41.8955Z"
              fill="#5928E5"
            />
            <path
              d="M120.082 62.4933L127.017 50.6444"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M133.919 53.8133L127.018 50.6444"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M140.206 48.75L133.919 53.8133"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M147.347 48.5433L140.206 48.75"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M120.082 43.9966V66.9366"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M116.323 62.4933H148.85"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M127.018 51.8154C127.584 51.8154 128.043 51.3528 128.043 50.7821C128.043 50.2114 127.584 49.7488 127.018 49.7488C126.452 49.7488 125.993 50.2114 125.993 50.7821C125.993 51.3528 126.452 51.8154 127.018 51.8154Z"
              fill="white"
            />
            <path
              d="M133.919 54.8466C134.485 54.8466 134.944 54.3839 134.944 53.8132C134.944 53.2425 134.485 52.7799 133.919 52.7799C133.353 52.7799 132.894 53.2425 132.894 53.8132C132.894 54.3839 133.353 54.8466 133.919 54.8466Z"
              fill="white"
            />
            <path
              d="M140.206 49.7832C140.772 49.7832 141.231 49.3206 141.231 48.7499C141.231 48.1792 140.772 47.7166 140.206 47.7166C139.64 47.7166 139.181 48.1792 139.181 48.7499C139.181 49.3206 139.64 49.7832 140.206 49.7832Z"
              fill="white"
            />
            <path
              d="M144.237 206.988C144.237 212.258 140.889 216.736 136.208 218.354V224.52H128.35V218.389C123.669 216.736 120.321 212.258 120.321 207.022C120.321 200.374 125.685 194.967 132.279 194.967C138.873 194.967 144.237 200.34 144.237 206.988Z"
              fill="#5928E5"
            />
            <path
              d="M136.208 225.037H128.35V226.311H136.208V225.037Z"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M136.208 227.103H128.35V228.378H136.208V227.103Z"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M132.279 231.03L136.208 228.378H128.35L132.279 231.03Z"
              fill="#5928E5"
            />
            <path
              d="M152.233 204.852H145.536"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M149.089 195.139L143.862 199.341"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M142.768 188.87L139.146 194.553"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M111.608 204.852H118.305"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M114.752 195.139L120.014 199.341"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M121.073 188.87L124.694 194.553"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M132.279 186.321V192.349"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M220.053 134.448H201.091V157.284H220.053V134.448Z"
              fill="#5928E5"
            />
            <path
              d="M211.307 114.677H209.837V144.23H211.307V114.677Z"
              fill="#5928E5"
            />
            <path
              d="M217.798 122.496L210.555 129.798L210.145 129.384L215.68 123.839L217.423 122.082L217.798 122.496Z"
              fill="#5928E5"
            />
            <path
              d="M210.487 122.082C210.487 122.082 203.551 113.092 211.136 102.69C211.136 102.69 217.32 110.543 210.487 122.082Z"
              fill="#5928E5"
            />
            <path
              d="M215.099 124.631C215.099 124.631 216.432 117.088 225.007 116.192C224.973 116.192 223.88 122.84 215.099 124.631Z"
              fill="#5928E5"
            />
            <path
              d="M68.6265 117.88H36.6465V150.154H68.6265V117.88Z"
              fill="#5928E5"
            />
            <path
              d="M55.5405 132.76H49.083V146.813H55.5405V132.76Z"
              fill="white"
            />
            <path
              d="M52.2949 133.483H45.8032L49.0491 127.8L52.2949 122.151L55.5407 127.8L58.7866 133.483H52.2949Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_249_8"
                x1="36.8291"
                y1="36.761"
                x2="231.6"
                y2="229.961"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="4.74173e-07" stop-color="#FFD9D9" />
                <stop offset="1" stop-color="#FFD9D9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="expertise-item reverse">
        <div className="left">
          <h2>Digital Marketing</h2>
          <p>
            With years of experience, we’ve mastered the art of building digital
            growth engines powered by effective marketing and branding. Our team
            of web designers and digital media experts work together to
            conceptualize a compelling digital strategy that positions your
            brand for success. We’ll leverage the power of the web to promote
            your brand, generate leads, and expand your consumer base.
          </p>
        </div>
        <div className="right">
          <div className="" ref={(el) => (sectionRefs.current[1] = el)}></div>
          <svg
            width="268"
            height="267"
            viewBox="0 0 268 267"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="digital-marketing"
          >
            <path
              d="M134.263 266.957C207.97 266.957 267.72 207.206 267.72 133.5C267.72 59.7937 207.97 0.0429688 134.263 0.0429688C60.5571 0.0429688 0.806396 59.7937 0.806396 133.5C0.806396 207.206 60.5571 266.957 134.263 266.957Z"
              fill="url(#paint0_linear_249_9)"
            />
            <path
              d="M101.492 136.901V142.019H99.9116V139.477L99.1215 142.019H97.7474L96.9573 139.477V142.019H95.3428V136.901H97.3008L98.4344 139.958L99.5337 136.901H101.492Z"
              fill="#5928E5"
            />
            <path
              d="M108.912 141.264H107.194L106.954 142.019H105.271L107.126 136.901H108.981L110.836 142.019H109.152L108.912 141.264ZM108.534 140.061L108.087 138.653L107.641 140.061H108.534Z"
              fill="#5928E5"
            />
            <path
              d="M117.225 142.019L116.263 140.199V142.019H114.648V136.901H117.019C117.431 136.901 117.774 136.97 118.084 137.107C118.393 137.244 118.599 137.451 118.736 137.691C118.874 137.931 118.942 138.241 118.942 138.55C118.942 138.893 118.839 139.202 118.668 139.443C118.461 139.718 118.221 139.889 117.843 140.027L118.942 141.985H117.225V142.019ZM116.263 139.134H116.881C117.019 139.134 117.156 139.099 117.225 139.031C117.294 138.962 117.328 138.859 117.328 138.687C117.328 138.55 117.294 138.447 117.225 138.378C117.156 138.309 117.019 138.275 116.881 138.275H116.263V139.134Z"
              fill="#5928E5"
            />
            <path
              d="M126.053 139.409L127.943 142.019H126.019L124.542 139.821V142.019H122.927V136.901H124.542V139.065L126.053 136.901H127.977L126.053 139.409Z"
              fill="#5928E5"
            />
            <path
              d="M133.405 138.172V138.79H134.985V139.992H133.405V140.714H135.191V141.985H131.79V136.866H135.191V138.137H133.405V138.172Z"
              fill="#5928E5"
            />
            <path
              d="M143.264 136.901V138.172H141.924V142.019H140.309V138.172H138.97V136.901H143.264Z"
              fill="#5928E5"
            />
            <path
              d="M148.726 136.901V142.019H147.111V136.901H148.726Z"
              fill="#5928E5"
            />
            <path
              d="M157.794 142.019H156.18L154.497 139.443V142.019H152.882V136.901H154.497L156.18 139.512V136.901H157.794V142.019Z"
              fill="#5928E5"
            />
            <path
              d="M166.004 137.347C166.417 137.657 166.691 138.103 166.76 138.653H165.077C165.008 138.55 164.939 138.481 164.802 138.412C164.699 138.344 164.562 138.344 164.39 138.344C164.081 138.344 163.84 138.447 163.668 138.653C163.497 138.859 163.428 139.134 163.428 139.477C163.428 139.889 163.531 140.164 163.703 140.37C163.875 140.576 164.184 140.68 164.562 140.68C164.939 140.68 165.214 140.542 165.455 140.233H164.184V139.099H166.794V140.714C166.588 141.092 166.279 141.435 165.867 141.676C165.489 141.951 164.974 142.088 164.39 142.088C163.875 142.088 163.394 141.985 163.016 141.744C162.638 141.538 162.329 141.229 162.123 140.817C161.917 140.439 161.813 139.958 161.813 139.477C161.813 138.996 161.917 138.515 162.123 138.137C162.329 137.76 162.638 137.45 163.016 137.21C163.394 137.004 163.875 136.866 164.39 136.866C165.043 136.866 165.592 137.038 166.004 137.347Z"
              fill="#5928E5"
            />
            <path
              d="M102.144 130.889C101.526 131.988 100.633 132.881 99.4994 133.534C98.3657 134.187 97.026 134.496 95.4802 134.496H89.3999V119.896H95.4802C96.9917 119.896 98.3314 120.206 99.4994 120.824C100.633 121.442 101.526 122.301 102.144 123.4C102.763 124.5 103.072 125.736 103.072 127.145C103.072 128.519 102.763 129.755 102.144 130.889ZM97.5069 129.549C98.1253 129 98.4001 128.175 98.4001 127.145C98.4001 126.114 98.0909 125.29 97.5069 124.74C96.8886 124.19 96.0985 123.881 95.068 123.881H93.9687V130.408H95.068C96.0985 130.374 96.923 130.099 97.5069 129.549Z"
              fill="#5928E5"
            />
            <path
              d="M110.664 119.862V134.462H106.095V119.862H110.664Z"
              fill="#5928E5"
            />
            <path
              d="M125.71 121.099C126.912 122.026 127.634 123.263 127.84 124.843H123.03C122.859 124.568 122.584 124.328 122.275 124.156C121.931 123.984 121.553 123.916 121.072 123.916C120.179 123.916 119.527 124.191 119.046 124.774C118.565 125.358 118.324 126.114 118.324 127.11C118.324 128.244 118.599 129.103 119.114 129.652C119.664 130.202 120.488 130.511 121.588 130.511C122.653 130.511 123.511 130.099 124.13 129.24H120.488V126.011H127.943V130.58C127.324 131.679 126.431 132.607 125.332 133.362C124.233 134.153 122.824 134.53 121.107 134.53C119.595 134.53 118.29 134.221 117.156 133.603C116.023 132.985 115.164 132.091 114.58 130.958C113.996 129.824 113.687 128.553 113.687 127.11C113.687 125.668 113.996 124.397 114.58 123.263C115.164 122.129 116.023 121.271 117.156 120.618C118.256 120 119.561 119.69 121.072 119.69C122.962 119.725 124.508 120.171 125.71 121.099Z"
              fill="#5928E5"
            />
            <path
              d="M135.5 119.862V134.462H130.931V119.862H135.5Z"
              fill="#5928E5"
            />
            <path
              d="M150.374 119.862V123.504H146.493V134.462H141.924V123.504H138.076V119.862H150.374Z"
              fill="#5928E5"
            />
            <path
              d="M162.329 132.263H157.485L156.764 134.462H151.955L157.279 119.862H162.569L167.894 134.462H163.085L162.329 132.263ZM161.23 128.828L159.924 124.775L158.619 128.828H161.23Z"
              fill="#5928E5"
            />
            <path
              d="M174.867 130.992H179.264V134.462H170.264V119.862H174.833V130.992H174.867Z"
              fill="#5928E5"
            />
            <path
              d="M132.958 213.265C177.011 213.265 212.723 177.553 212.723 133.5C212.723 89.4471 177.011 53.7351 132.958 53.7351C88.9049 53.7351 53.1929 89.4471 53.1929 133.5C53.1929 177.553 88.9049 213.265 132.958 213.265Z"
              stroke="#5928E5"
              stroke-miterlimit="10"
              stroke-dasharray="9.99 9.99"
            />
            <path
              d="M120.248 210.517C120.248 210.517 139.485 203.853 148.382 190.799C148.382 190.799 160.371 205.879 157.554 218.796C157.554 218.796 138.695 212.681 125.469 221.819C125.469 221.819 113.79 219.861 120.248 210.517Z"
              fill="#5928E5"
            />
            <path
              d="M127.53 216.803L131.927 230.2C131.927 230.2 135.706 234.013 137.801 228.277C133.404 215.944 133.404 215.944 133.404 215.944L127.53 216.803Z"
              fill="#5928E5"
            />
            <path
              d="M147.832 191.211C147.832 191.211 146.149 210.036 157.004 219.208"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M149.172 203.2L151.37 209.624C151.37 209.624 162.123 201.62 149.172 203.2Z"
              fill="white"
            />
            <path
              d="M154.806 195.574L163.565 186.058"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M157.966 199.181L164.733 195.127"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M159.924 205.158L167.962 203.2"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M160.508 210.07L176.482 211.135"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M157.21 37.7615H111.419V69.7431H157.21V37.7615Z"
              fill="#5928E5"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M147.386 44.9753H121.278V62.3917H147.386V44.9753Z"
              fill="white"
              stroke="#FCFCFC"
              stroke-miterlimit="10"
            />
            <path
              d="M134.332 54.3878L147.386 44.4258H121.278L134.332 54.3878Z"
              fill="white"
              stroke="#5928E5"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M53.1929 135.389V143.668H36.2917V135.389C38.4216 131.37 40.4483 129.103 42.3033 128.004C40.3453 127.008 39.0055 124.981 39.0055 122.645C39.0055 119.313 41.685 116.633 45.0171 116.633C48.3493 116.633 51.0287 119.313 51.0287 122.645C51.0287 124.981 49.689 127.008 47.7309 128.004C51.1317 130.031 53.1929 135.389 53.1929 135.389Z"
              fill="#5928E5"
            />
            <path
              d="M70.094 135.389V143.668H53.1929V135.389C55.3227 131.37 57.3494 129.103 59.2044 128.004C57.2464 127.008 55.9067 124.981 55.9067 122.645C55.9067 119.313 58.5861 116.633 61.9182 116.633C65.2504 116.633 67.9298 119.313 67.9298 122.645C67.9298 124.981 66.5901 127.008 64.632 128.004C68.0329 130.031 70.094 135.389 70.094 135.389Z"
              fill="#5928E5"
            />
            <path
              d="M61.6433 142.122V150.401H44.7422V142.122C46.872 138.103 48.8988 135.836 50.7538 134.737C48.7957 133.74 47.456 131.714 47.456 129.378C47.456 126.046 50.1354 123.366 53.4676 123.366C56.7997 123.366 59.4791 126.046 59.4791 129.378C59.4791 131.714 58.1394 133.74 56.1814 134.737C59.5822 136.798 61.6433 142.122 61.6433 142.122Z"
              fill="#5928E5"
              stroke="#F9F9F9"
              stroke-miterlimit="10"
            />
            <path
              d="M231.616 139.649H202.795H197.127V117.286H231.616V139.649Z"
              fill="#5928E5"
              stroke="#F9F9F9"
              stroke-miterlimit="10"
            />
            <path
              d="M202.349 149.714L212.414 139.374H202.795L202.349 149.714Z"
              fill="#5928E5"
            />
            <path
              d="M202.349 124.019H225.09"
              stroke="#FDFDFF"
              stroke-miterlimit="10"
            />
            <path
              d="M202.349 128.485H225.09"
              stroke="#FDFDFF"
              stroke-miterlimit="10"
            />
            <path
              d="M202.349 132.95H217.876"
              stroke="#FDFDFF"
              stroke-miterlimit="10"
            />
            <defs>
              <linearGradient
                id="paint0_linear_249_9"
                x1="231.248"
                y1="36.5154"
                x2="36.9945"
                y2="230.769"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="4.74173e-07" stop-color="#FFD9D9" />
                <stop offset="1" stop-color="#FFD9D9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="expertise-item">
        <div className="left">
          <h2>Business Launchpad</h2>
          <p>
            Our business development team is dedicated to studying your brand,
            rediscovering its core values, and reintegrating its identity. We’ll
            work with you to identify new business opportunities, develop
            strategic partnerships, and create a roadmap for long-term growth.
            Let us help you take your brand to the next level.
          </p>
        </div>
        <div className="right">
          <div className="" ref={(el) => (sectionRefs.current[2] = el)}></div>
          <svg
            width="269"
            height="267"
            viewBox="0 0 269 267"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="business-marketing"
          >
            <path
              d="M134.257 266.957C208.36 266.957 268.433 207.206 268.433 133.5C268.433 59.7937 208.36 0.0429688 134.257 0.0429688C60.1532 0.0429688 0.0803223 59.7937 0.0803223 133.5C0.0803223 207.206 60.1532 266.957 134.257 266.957Z"
              fill="url(#paint0_linear_249_10)"
            />
            <path
              d="M91.9835 141.882C91.7762 142.26 91.4654 142.569 91.051 142.809C90.6365 143.05 90.1875 143.153 89.635 143.153H87.4937V138.034H89.635C90.1875 138.034 90.6365 138.137 91.051 138.344C91.4654 138.55 91.7762 138.859 91.9835 139.237C92.1907 139.615 92.2943 140.061 92.2943 140.542C92.2943 141.057 92.1907 141.504 91.9835 141.882ZM90.3257 141.435C90.5329 141.229 90.6365 140.954 90.6365 140.576C90.6365 140.199 90.5329 139.924 90.3257 139.718C90.1185 139.512 89.8422 139.408 89.4623 139.408H89.0824V141.676H89.4623C89.8422 141.71 90.1185 141.607 90.3257 141.435Z"
              fill="#5928E5"
            />
            <path
              d="M97.924 139.305V139.924H99.5127V141.126H97.924V141.847H99.7199V143.118H96.3008V138H99.7199V139.271H97.924V139.305Z"
              fill="#5928E5"
            />
            <path
              d="M105.177 138.034L106.247 141.538L107.283 138.034H109.01L107.283 143.153H105.211L103.484 138.034H105.177Z"
              fill="#5928E5"
            />
            <path
              d="M114.398 139.305V139.924H115.987V141.126H114.398V141.847H116.194V143.118H112.775V138H116.194V139.271H114.398V139.305Z"
              fill="#5928E5"
            />
            <path
              d="M121.893 141.951H123.447V143.153H120.27V138.034H121.893V141.951Z"
              fill="#5928E5"
            />
            <path
              d="M131.217 138.275C131.632 138.515 131.943 138.825 132.184 139.202C132.426 139.58 132.53 140.061 132.53 140.542C132.53 141.023 132.426 141.504 132.184 141.882C131.943 142.294 131.632 142.603 131.217 142.809C130.803 143.015 130.388 143.153 129.905 143.153C129.421 143.153 128.972 143.05 128.558 142.809C128.144 142.569 127.833 142.26 127.591 141.882C127.349 141.47 127.246 141.023 127.246 140.542C127.246 140.061 127.349 139.58 127.591 139.202C127.833 138.79 128.144 138.481 128.558 138.275C128.972 138.069 129.421 137.931 129.905 137.931C130.388 137.931 130.837 138.069 131.217 138.275ZM129.145 139.752C128.972 139.958 128.869 140.233 128.869 140.576C128.869 140.92 128.972 141.195 129.145 141.401C129.318 141.607 129.56 141.71 129.905 141.71C130.216 141.71 130.458 141.607 130.63 141.401C130.803 141.195 130.907 140.92 130.907 140.576C130.907 140.233 130.803 139.958 130.63 139.752C130.458 139.546 130.216 139.443 129.905 139.443C129.594 139.443 129.318 139.546 129.145 139.752Z"
              fill="#5928E5"
            />
            <path
              d="M138.194 141.538V143.153H136.571V138.034H138.816C139.437 138.034 139.921 138.206 140.266 138.515C140.612 138.825 140.784 139.271 140.784 139.786C140.784 140.096 140.715 140.405 140.543 140.68C140.404 140.954 140.163 141.16 139.886 141.298C139.61 141.435 139.23 141.538 138.816 141.538H138.194ZM139.127 139.786C139.127 139.477 138.954 139.305 138.643 139.305H138.194V140.233H138.643C138.988 140.267 139.127 140.096 139.127 139.786Z"
              fill="#5928E5"
            />
            <path
              d="M150.904 138.034V143.153H149.315V140.611L148.521 143.153H147.139L146.345 140.611V143.153H144.722V138.034H146.69L147.83 141.092L148.935 138.034H150.904Z"
              fill="#5928E5"
            />
            <path
              d="M156.706 139.305V139.924H158.294V141.126H156.706V141.847H158.502V143.118H155.083V138H158.502V139.271H156.706V139.305Z"
              fill="#5928E5"
            />
            <path
              d="M167.481 143.153H165.893L164.2 140.576V143.153H162.577V138.034H164.2L165.893 140.645V138.034H167.516V143.153H167.481Z"
              fill="#5928E5"
            />
            <path
              d="M175.701 138.034V139.305H174.354V143.153H172.731V139.305H171.384V138.034H175.701Z"
              fill="#5928E5"
            />
            <path
              d="M96.5421 129.756C96.9566 130.271 97.1638 130.889 97.1638 131.611C97.1638 132.676 96.8184 133.5 96.0932 134.084C95.4024 134.668 94.3663 134.943 93.0539 134.943H86.6646V122.782H92.8812C94.1246 122.782 95.0916 123.057 95.8169 123.607C96.5421 124.156 96.8875 124.912 96.8875 125.943C96.8875 126.664 96.7148 127.248 96.3349 127.729C95.955 128.21 95.4715 128.553 94.8498 128.691C95.5406 128.897 96.1277 129.24 96.5421 129.756ZM90.4636 127.523H91.9487C92.2941 127.523 92.5358 127.454 92.7085 127.317C92.8812 127.179 92.9503 126.973 92.9503 126.698C92.9503 126.389 92.8812 126.183 92.7085 126.046C92.5358 125.908 92.2941 125.84 91.9487 125.84H90.4636V127.523ZM92.9848 131.679C93.1575 131.542 93.2266 131.336 93.2266 131.027C93.2266 130.443 92.8812 130.134 92.1905 130.134H90.4636V131.851H92.1905C92.5358 131.885 92.8121 131.817 92.9848 131.679Z"
              fill="#5928E5"
            />
            <path
              d="M103.139 122.782V129.653C103.139 130.202 103.277 130.649 103.519 130.992C103.76 131.336 104.175 131.473 104.727 131.473C105.28 131.473 105.695 131.301 105.971 130.992C106.247 130.649 106.351 130.237 106.351 129.653V122.782H110.15V129.653C110.15 130.821 109.908 131.782 109.425 132.607C108.941 133.431 108.285 134.05 107.421 134.462C106.592 134.874 105.66 135.08 104.624 135.08C103.588 135.08 102.655 134.874 101.861 134.462C101.067 134.05 100.445 133.431 99.9959 132.641C99.5469 131.817 99.3052 130.855 99.3052 129.653V122.782H103.139Z"
              fill="#5928E5"
            />
            <path
              d="M121.65 133.26C121.305 133.809 120.752 134.256 120.062 134.599C119.371 134.943 118.542 135.08 117.575 135.08C116.125 135.08 114.916 134.737 113.949 134.05C112.982 133.363 112.498 132.366 112.395 131.061H116.504C116.574 131.748 116.884 132.092 117.437 132.092C117.644 132.092 117.782 132.023 117.92 131.92C118.059 131.817 118.093 131.679 118.093 131.473C118.093 131.198 117.955 130.958 117.644 130.786C117.333 130.614 116.884 130.443 116.228 130.202C115.468 129.962 114.812 129.687 114.294 129.446C113.776 129.172 113.327 128.794 112.947 128.279C112.567 127.763 112.395 127.076 112.395 126.252C112.395 125.462 112.602 124.775 113.016 124.225C113.431 123.675 113.983 123.263 114.709 122.954C115.399 122.679 116.228 122.542 117.092 122.542C118.542 122.542 119.716 122.885 120.58 123.572C121.443 124.259 121.927 125.221 121.996 126.492H117.851C117.782 125.84 117.541 125.53 117.057 125.53C116.884 125.53 116.746 125.565 116.677 125.668C116.574 125.771 116.504 125.908 116.504 126.114C116.504 126.389 116.643 126.595 116.953 126.767C117.264 126.939 117.713 127.111 118.335 127.351C119.129 127.626 119.751 127.901 120.269 128.175C120.787 128.45 121.236 128.828 121.616 129.343C121.996 129.859 122.203 130.546 122.203 131.336C122.203 132.057 122.03 132.676 121.65 133.26Z"
              fill="#5928E5"
            />
            <path
              d="M128.316 122.782V134.943H124.482V122.782H128.316Z"
              fill="#5928E5"
            />
            <path
              d="M142.718 134.943H138.885L134.809 128.828V134.943H131.01V122.782H134.844L138.919 129V122.782H142.753V134.943H142.718Z"
              fill="#5928E5"
            />
            <path
              d="M149.246 125.805V127.317H153.045V130.202H149.246V131.92H153.563V134.943H145.412V122.782H153.563V125.805H149.246Z"
              fill="#5928E5"
            />
            <path
              d="M164.856 133.26C164.511 133.809 163.958 134.256 163.268 134.599C162.577 134.943 161.748 135.08 160.781 135.08C159.33 135.08 158.122 134.737 157.155 134.05C156.188 133.363 155.704 132.366 155.6 131.061H159.676C159.745 131.748 160.056 132.092 160.608 132.092C160.816 132.092 160.954 132.023 161.092 131.92C161.23 131.817 161.265 131.679 161.265 131.473C161.265 131.198 161.126 130.958 160.816 130.786C160.539 130.614 160.056 130.443 159.4 130.202C158.64 129.962 157.984 129.687 157.465 129.446C156.947 129.172 156.498 128.794 156.119 128.279C155.739 127.763 155.566 127.076 155.566 126.252C155.566 125.462 155.773 124.775 156.188 124.225C156.602 123.675 157.155 123.263 157.88 122.954C158.571 122.679 159.4 122.542 160.263 122.542C161.714 122.542 162.888 122.885 163.751 123.572C164.615 124.259 165.098 125.221 165.167 126.492H161.023C160.954 125.84 160.712 125.53 160.228 125.53C160.056 125.53 159.918 125.565 159.814 125.668C159.71 125.771 159.641 125.908 159.641 126.114C159.641 126.389 159.779 126.595 160.09 126.767C160.367 126.939 160.85 127.111 161.472 127.351C162.266 127.626 162.888 127.901 163.406 128.175C163.924 128.45 164.373 128.828 164.753 129.343C165.133 129.859 165.34 130.546 165.34 131.336C165.409 132.057 165.236 132.676 164.856 133.26Z"
              fill="#5928E5"
            />
            <path
              d="M176.599 133.26C176.254 133.809 175.701 134.256 175.01 134.599C174.32 134.943 173.491 135.08 172.524 135.08C171.073 135.08 169.864 134.737 168.897 134.05C167.93 133.363 167.447 132.366 167.343 131.061H171.419C171.488 131.748 171.798 132.092 172.351 132.092C172.558 132.092 172.696 132.023 172.835 131.92C172.973 131.817 173.007 131.679 173.007 131.473C173.007 131.198 172.869 130.958 172.558 130.786C172.282 130.614 171.798 130.443 171.142 130.202C170.382 129.962 169.726 129.687 169.208 129.446C168.69 129.172 168.241 128.794 167.861 128.279C167.481 127.763 167.309 127.076 167.309 126.252C167.309 125.462 167.516 124.775 167.93 124.225C168.345 123.675 168.897 123.263 169.623 122.954C170.313 122.679 171.142 122.542 172.006 122.542C173.456 122.542 174.63 122.885 175.494 123.572C176.357 124.259 176.841 125.221 176.91 126.492H172.765C172.696 125.84 172.455 125.53 171.971 125.53C171.798 125.53 171.66 125.565 171.557 125.668C171.453 125.771 171.384 125.908 171.384 126.114C171.384 126.389 171.522 126.595 171.833 126.767C172.109 126.939 172.593 127.111 173.214 127.351C174.009 127.626 174.63 127.901 175.149 128.175C175.667 128.45 176.116 128.828 176.495 129.343C176.875 129.859 177.083 130.546 177.083 131.336C177.152 132.057 176.979 132.676 176.599 133.26Z"
              fill="#5928E5"
            />
            <path
              d="M132.944 213.265C177.234 213.265 213.139 177.553 213.139 133.5C213.139 89.4471 177.234 53.7351 132.944 53.7351C88.6535 53.7351 52.749 89.4471 52.749 133.5C52.749 177.553 88.6535 213.265 132.944 213.265Z"
              stroke="#5928E5"
              stroke-miterlimit="10"
              stroke-dasharray="9.99 9.99"
            />
            <path
              d="M210.584 154.764C221.38 154.764 230.132 146.059 230.132 135.321C230.132 124.582 221.38 115.877 210.584 115.877C199.788 115.877 191.036 124.582 191.036 135.321C191.036 146.059 199.788 154.764 210.584 154.764Z"
              fill="#5928E5"
            />
            <path
              d="M210.584 150.229C218.862 150.229 225.573 143.554 225.573 135.321C225.573 127.087 218.862 120.412 210.584 120.412C202.306 120.412 195.595 127.087 195.595 135.321C195.595 143.554 202.306 150.229 210.584 150.229Z"
              stroke="#FDFDFD"
              stroke-miterlimit="10"
            />
            <path
              d="M216.455 140.439C215.971 141.16 215.281 141.71 214.348 142.157C213.416 142.569 212.31 142.809 211.032 142.809V144.596H209.962V142.741C208.27 142.603 206.888 142.088 205.817 141.229C204.747 140.37 204.16 139.202 204.056 137.691H208.788C208.857 138.515 209.237 139.065 209.996 139.305V136.386C209.789 136.351 209.616 136.282 209.478 136.214C208.373 135.905 207.441 135.595 206.715 135.252C205.99 134.943 205.368 134.427 204.816 133.775C204.298 133.122 204.021 132.263 204.021 131.164C204.021 130.202 204.298 129.378 204.816 128.691C205.334 128.004 206.059 127.454 206.957 127.076C207.855 126.698 208.857 126.492 209.996 126.458V124.637H211.067V126.492C212.828 126.595 214.21 127.111 215.246 127.935C216.248 128.794 216.835 129.927 216.938 131.37H212.172C212.069 130.614 211.723 130.133 211.102 129.927V132.813C211.447 132.916 211.723 133.019 211.93 133.053C213.036 133.397 213.968 133.74 214.659 134.05C215.35 134.393 215.971 134.874 216.489 135.492C217.007 136.145 217.249 136.97 217.249 138C217.18 138.928 216.938 139.718 216.455 140.439ZM209.996 132.401V129.859C209.202 129.927 208.788 130.305 208.788 130.992C208.788 131.576 209.202 132.057 209.996 132.401ZM212.069 139.031C212.31 138.79 212.449 138.481 212.449 138.137C212.449 137.828 212.345 137.553 212.103 137.313C211.861 137.073 211.516 136.901 211.067 136.729V139.409C211.447 139.409 211.792 139.271 212.069 139.031Z"
              fill="#FCFCFC"
            />
            <path
              d="M60.2441 121.236H44.9097V132.057H60.2441V121.236Z"
              fill="#5928E5"
            />
            <path
              d="M42.3538 140.473H32.1309V147.687H42.3538V140.473Z"
              fill="#5928E5"
            />
            <path
              d="M57.6883 140.473H47.4653V147.687H57.6883V140.473Z"
              fill="#5928E5"
            />
            <path
              d="M72.9881 140.473H62.7651V147.687H72.9881V140.473Z"
              fill="#5928E5"
            />
            <path
              d="M32.1309 135.286H72.9882"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M52.5767 130.683V141.779"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M37.2422 135.286V142.706"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M67.8765 135.286V142.706"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M144.791 42.124H121.098V72.3193H144.791V42.124Z"
              fill="#5928E5"
            />
            <path
              d="M137.538 38.4827H128.351V43.7042H137.538V38.4827Z"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M140.335 47.9636L139.61 48.2041C136.294 49.3377 133.877 52.1889 133.359 55.6584L132.564 60.983C132.115 64.0746 129.939 66.6854 126.935 67.6816L125.312 68.2312"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M137.676 46.9331L140.577 47.9637L139.541 50.8149"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M128.109 63.8C129.025 63.8 129.767 63.0617 129.767 62.1511C129.767 61.2404 129.025 60.5022 128.109 60.5022C127.193 60.5022 126.451 61.2404 126.451 62.1511C126.451 63.0617 127.193 63.8 128.109 63.8Z"
              fill="white"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M125.588 47.895L128.973 51.2959"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M128.973 47.895L125.588 51.2959"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M137.089 62.6663L140.508 66.0671"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M140.508 62.6663L137.089 66.0671"
              stroke="white"
              stroke-miterlimit="10"
            />
            <path
              d="M153.149 222.918L152.7 223.365C151.871 224.189 150.489 224.189 149.66 223.365L135.155 208.937L138.678 205.433L153.183 219.861C153.977 220.719 153.977 222.059 153.149 222.918Z"
              fill="#5928E5"
            />
            <path
              d="M128.904 211.067C135.351 211.067 140.577 205.868 140.577 199.456C140.577 193.043 135.351 187.845 128.904 187.845C122.456 187.845 117.23 193.043 117.23 199.456C117.23 205.868 122.456 211.067 128.904 211.067Z"
              fill="white"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M128.904 209.143C134.282 209.143 138.643 204.806 138.643 199.456C138.643 194.106 134.282 189.769 128.904 189.769C123.525 189.769 119.164 194.106 119.164 199.456C119.164 204.806 123.525 209.143 128.904 209.143Z"
              fill="#5928E5"
              stroke="#5928E5"
              stroke-miterlimit="10"
            />
            <path
              d="M123.585 199.834L127.626 204.059L133.739 195.127"
              stroke="white"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <defs>
              <linearGradient
                id="paint0_linear_249_10"
                x1="36.7632"
                y1="36.5291"
                x2="231.014"
                y2="231.827"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="4.74173e-07" stop-color="#FFD9D9" />
                <stop offset="1" stop-color="#FFD9D9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Expertise;
