import React from "react";
import "./css/videoPlayer.css";

const VideoPlayer = ({ videoIds }) => {
  return (
    <div className="youtube-section">
      <h1 className="video-product">Product Videos</h1>
      <div className="video-container">
        {videoIds.map((id, index) => {
          return (
            <iframe
              className="video"
              key={index}
              title="YouTube video player"
              src={`https://www.youtube.com/embed/${id}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        })}
      </div>
    </div>
  );
};

export default VideoPlayer;
