import React from "react";
import "./css/hero.css";
import "../css/generalElement.css";
const Hero = () => {
  return (
    <div className="home-hero">
      <svg
        width="730"
        height="798"
        viewBox="0 0 730 798"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="home-hero-bg-lines"
      >
        <path
          d="M55.677 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M100.661 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M145.645 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M190.628 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M235.612 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M280.596 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M325.62 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M370.604 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M415.588 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M460.571 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M505.555 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M550.539 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M595.523 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M640.507 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M685.49 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M730.474 47.9484V726.565"
          stroke="#4924CE"
          stroke-width="3"
          stroke-miterlimit="10"
        />
      </svg>
      <div className="hero-container">
        <div className="hero-left">
          <h1>Crafting Extraordinary Brands out of Ordinary Businesses</h1>
          <br />
          <p>Branding | Business Development | Digital Marketing</p>
          <svg
            width="5vw"
            height="5vw"
            viewBox="0 0 24 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="down-arrow"
          >
            <path
              d="M10.9393 70.9657C11.5251 71.5515 12.4749 71.5515 13.0607 70.9657L22.6066 61.4197C23.1924 60.834 23.1924 59.8842 22.6066 59.2984C22.0208 58.7126 21.0711 58.7126 20.4853 59.2984L12 67.7837L3.51472 59.2984C2.92893 58.7126 1.97919 58.7126 1.3934 59.2984C0.807614 59.8842 0.807614 60.834 1.3934 61.4197L10.9393 70.9657ZM10.5 6.54781e-08L10.5 69.905L13.5 69.905L13.5 -6.54781e-08L10.5 6.54781e-08Z"
              fill="#FFD9D9"
            />
          </svg>
        </div>
        <div className="hero-right">
          <svg
            width="35vw"
            height="35vw"
            viewBox="0 0 539 568"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.597168 0.547424V567.692"
              stroke="url(#paint0_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M54.4058 0.547424V567.692"
              stroke="url(#paint1_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M108.179 0.547424V567.692"
              stroke="url(#paint2_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M161.953 0.547424V567.692"
              stroke="url(#paint3_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M215.726 0.547424V567.692"
              stroke="url(#paint4_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M269.5 0.547424V567.692"
              stroke="url(#paint5_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M323.273 0.547424V567.692"
              stroke="url(#paint6_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M377.047 0.547424V567.692"
              stroke="url(#paint7_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M430.82 0.547424V567.692"
              stroke="url(#paint8_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M484.594 0.547424V567.692"
              stroke="url(#paint9_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M538.402 0.547424V567.692"
              stroke="url(#paint10_linear_378_669)"
              stroke-miterlimit="10"
            />
            <path
              d="M469.016 288.401H360.075V502.124H469.016V288.401Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M82.1813 237.554C118.077 237.554 147.177 208.494 147.177 172.647C147.177 136.8 118.077 107.74 82.1813 107.74C46.2854 107.74 17.186 136.8 17.186 172.647C17.186 208.494 46.2854 237.554 82.1813 237.554Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M269.43 521.37C396.712 521.37 499.893 418.328 499.893 291.22C499.893 164.111 396.712 61.0693 269.43 61.0693C142.149 61.0693 38.9673 164.111 38.9673 291.22C38.9673 418.328 142.149 521.37 269.43 521.37Z"
              fill="url(#paint11_linear_378_669)"
            />
            <path
              d="M397.574 443.064C432.257 443.064 460.373 414.986 460.373 380.349C460.373 345.713 432.257 317.635 397.574 317.635C362.89 317.635 334.774 345.713 334.774 380.349C334.774 414.986 362.89 443.064 397.574 443.064Z"
              fill="#5928E5"
            />
            <path
              opacity="0.5"
              d="M397.574 443.064C432.257 443.064 460.373 414.986 460.373 380.349C460.373 345.713 432.257 317.635 397.574 317.635C362.89 317.635 334.774 345.713 334.774 380.349C334.774 414.986 362.89 443.064 397.574 443.064Z"
              fill="#FFD9D9"
            />
            <path
              d="M397.574 431.335C426.387 431.335 449.744 408.01 449.744 379.236C449.744 350.462 426.387 327.136 397.574 327.136C368.761 327.136 345.403 350.462 345.403 379.236C345.403 408.01 368.761 431.335 397.574 431.335Z"
              fill="#5928E5"
            />
            <path
              opacity="0.5"
              d="M397.574 416.718C419.054 416.718 436.466 399.329 436.466 377.879C436.466 356.428 419.054 339.039 397.574 339.039C376.094 339.039 358.681 356.428 358.681 377.879C358.681 399.329 376.094 416.718 397.574 416.718Z"
              fill="#FFD9D9"
            />
            <path
              d="M397.574 401.022C410.373 401.022 420.749 390.66 420.749 377.878C420.749 365.097 410.373 354.735 397.574 354.735C384.774 354.735 374.398 365.097 374.398 377.878C374.398 390.66 384.774 401.022 397.574 401.022Z"
              fill="#5928E5"
            />
            <path
              opacity="0.5"
              d="M397.574 389.99C404.079 389.99 409.353 384.723 409.353 378.226C409.353 371.73 404.079 366.463 397.574 366.463C391.068 366.463 385.794 371.73 385.794 378.226C385.794 384.723 391.068 389.99 397.574 389.99Z"
              fill="#FFD9D9"
            />
            <path
              d="M381.927 377.983H161.291C152.683 377.983 145.644 370.953 145.644 362.356V211.278C145.644 202.682 152.683 195.651 161.291 195.651H381.927C390.535 195.651 397.574 202.682 397.574 211.278V362.356C397.574 370.953 390.535 377.983 381.927 377.983Z"
              fill="#5928E5"
            />
            <path
              d="M381.787 377.983H161.395C152.718 377.983 145.608 370.883 145.608 362.217V211.417C145.608 202.751 152.718 195.651 161.395 195.651H381.787C390.464 195.651 397.574 202.751 397.574 211.417V362.252C397.574 370.883 390.464 377.983 381.787 377.983Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M304.42 410.036H239.425V414.038H304.42V410.036Z"
              fill="#FFD9D9"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.5"
              d="M388.338 205.396H154.809V342.066H388.338V205.396Z"
              fill="#FFD9D9"
            />
            <path
              d="M302.434 410.036H241.098C245.28 410.036 248.66 406.626 248.66 402.414V377.983H294.871V402.414C294.836 406.626 298.252 410.036 302.434 410.036Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M229.144 443.934C252.587 443.934 271.591 424.956 271.591 401.544C271.591 378.133 252.587 359.155 229.144 359.155C205.701 359.155 186.696 378.133 186.696 401.544C186.696 424.956 205.701 443.934 229.144 443.934Z"
              fill="#5928E5"
            />
            <path
              d="M229.144 443.934C252.587 443.934 271.591 424.956 271.591 401.544C271.591 378.133 252.587 359.155 229.144 359.155C205.701 359.155 186.696 378.133 186.696 401.544C186.696 424.956 205.701 443.934 229.144 443.934Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.5"
              d="M229.144 401.544L266.642 381.672C266.642 381.672 255.699 359.503 229.144 359.155C229.144 401.544 229.144 401.544 229.144 401.544Z"
              fill="#FFD9D9"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M193.387 356.092H64.1636V443.168H193.387V356.092Z"
              fill="#5928E5"
            />
            <path
              d="M193.387 356.092H64.1636V443.168H193.387V356.092Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.5"
              d="M98.1773 403.215H80.4038V443.203H98.1773V403.215Z"
              fill="#FFD9D9"
            />
            <path
              opacity="0.5"
              d="M125.291 395.245H107.517V443.168H125.291V395.245Z"
              fill="#FFD9D9"
            />
            <path
              opacity="0.5"
              d="M152.404 369.108H134.63V443.203H152.404V369.108Z"
              fill="#FFD9D9"
            />
            <path
              opacity="0.5"
              d="M179.517 382.716H161.744V443.203H179.517V382.716Z"
              fill="#FFD9D9"
            />
            <path
              opacity="0.5"
              d="M488.463 275.524L439.952 227.078L449.117 217.925L497.628 266.371C499.022 267.763 499.022 270.025 497.628 271.417L493.516 275.524C492.122 276.916 489.857 276.916 488.463 275.524Z"
              fill="#FFD9D9"
            />
            <path
              d="M414.546 235.466C437.989 235.466 456.993 216.487 456.993 193.076C456.993 169.665 437.989 150.686 414.546 150.686C391.103 150.686 372.099 169.665 372.099 193.076C372.099 216.487 391.103 235.466 414.546 235.466Z"
              fill="#5928E5"
            />
            <path
              d="M414.546 235.466C437.989 235.466 456.993 216.487 456.993 193.076C456.993 169.665 437.989 150.686 414.546 150.686C391.103 150.686 372.099 169.665 372.099 193.076C372.099 216.487 391.103 235.466 414.546 235.466Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.5"
              d="M414.546 226.765C433.177 226.765 448.281 211.682 448.281 193.076C448.281 174.47 433.177 159.387 414.546 159.387C395.915 159.387 380.811 174.47 380.811 193.076C380.811 211.682 395.915 226.765 414.546 226.765Z"
              fill="#FFD9D9"
            />
            <path
              opacity="0.72"
              d="M430.507 215.071C425.558 219.248 419.111 221.684 412.071 221.475C397.818 221.023 386.178 209.399 385.725 195.164C385.516 188.134 387.955 181.696 392.137 176.754C392.939 175.779 394.402 175.744 395.309 176.649C396.11 177.45 396.145 178.703 395.448 179.573C391.963 183.714 389.907 189.143 390.081 195.025C390.43 206.997 400.188 216.777 412.211 217.194C418.135 217.403 423.572 215.35 427.754 211.835C428.625 211.104 429.88 211.173 430.681 211.974C431.483 212.774 431.448 214.236 430.507 215.071Z"
              fill="#FFD9D9"
            />
            <path
              d="M175.091 150.234H58.9712V266.197H175.091V150.234Z"
              fill="#5928E5"
            />
            <path
              d="M59.7378 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M70.9595 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M82.1812 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M93.4028 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M104.625 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M115.846 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M127.068 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M138.29 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M149.477 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M160.698 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M171.92 151.348V266.197"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 150.234H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 161.44H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 172.647H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 183.853H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 195.06H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 206.231H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 217.438H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 228.644H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 239.851H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 251.057H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M173.941 262.264H58.9712"
              stroke="#FFD9D9"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
            <path
              d="M192.203 159.004V179.851L185.233 172.925L171.293 186.812L157.387 200.733L143.447 186.812L129.542 200.733H129.507L115.602 214.654L101.662 200.733L73.8172 228.54L66.8472 221.579L73.8172 214.654L87.7572 200.733L101.662 186.812L115.602 200.733L143.447 172.925L157.387 186.812L171.293 172.925L178.263 165.965L171.293 159.004H192.203Z"
              fill="#5928E5"
            />
            <path
              d="M192.203 159.004V179.851L185.233 172.925L171.293 186.812L157.387 200.733L143.447 186.812L129.542 200.733H129.507L115.602 214.654L101.662 200.733L73.8172 228.54L66.8472 221.579L73.8172 214.654L87.7572 200.733L101.662 186.812L115.602 200.733L143.447 172.925L157.387 186.812L171.293 172.925L178.263 165.965L171.293 159.004H192.203Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M250.437 168.436C266.373 168.436 279.293 155.534 279.293 139.619C279.293 123.704 266.373 110.802 250.437 110.802C234.5 110.802 221.581 123.704 221.581 139.619C221.581 155.534 234.5 168.436 250.437 168.436Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M250.437 162.972C263.39 162.972 273.891 152.485 273.891 139.549C273.891 126.614 263.39 116.127 250.437 116.127C237.484 116.127 226.983 126.614 226.983 139.549C226.983 152.485 237.484 162.972 250.437 162.972Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.5"
              d="M248.834 124.201H252.876V126.707C252.876 126.707 256.675 126.603 260.195 128.726L257.511 134.99C257.511 134.99 254.27 132.693 251.657 132.867C249.008 133.007 248.067 134.607 250.437 135.512C250.437 135.512 252.179 136.069 253.399 136.174C254.619 136.278 258.278 137.183 259.637 138.819C260.996 140.454 262.251 142.334 260.543 147.032C258.836 151.73 252.667 151.835 252.667 151.835V154.619H248.59V151.8C248.59 151.8 243.258 151.73 239.703 148.946L242.456 142.403C242.456 142.403 246.499 145.327 249.322 145.327C252.145 145.327 254.236 144.491 251.657 142.717C251.657 142.717 245.314 141.498 243.711 140.35C242.108 139.201 239.18 135.652 241.829 130.536C241.829 130.536 244.129 127.16 248.869 126.812L248.834 124.201Z"
              fill="#FFD9D9"
            />
            <path
              d="M345.055 453.296H282.081V494.816H345.055V453.296Z"
              fill="#5928E5"
            />
            <path
              d="M345.055 453.296H282.081V494.816H345.055V453.296Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M313.655 472.82L342.824 453.574H284.451L313.655 472.82Z"
              stroke="#FFD9D9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <defs>
              <linearGradient
                id="paint0_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint10_linear_378_669"
                x1="-nan"
                y1="-nan"
                x2="-nan"
                y2="-nan"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="0.1702" stop-color="#E905FB" />
                <stop offset="0.5264" stop-color="#AF13F2" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
              <linearGradient
                id="paint11_linear_378_669"
                x1="418.826"
                y1="466.504"
                x2="120.529"
                y2="115.551"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF00FF" />
                <stop offset="1" stop-color="#5928E5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Hero;
