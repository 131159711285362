import React from "react";
import {
  FaLinkedin,
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaTwitterSquare,
  FaTiktok,
} from "react-icons/fa";
import "./css/footer.css";

const SocialMediaLinks = () => {
  return (
    <div className="social-media-links">
      <ul className="icon-list">
        <li className="list-item">
          <a
            href="https://www.linkedin.com/company/justbrandify/"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaLinkedin className="icon" />
          </a>
        </li>
        <li className="list-item">
          <a
            href="https://www.facebook.com/justbrandify/"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaFacebookSquare className="icon" />
          </a>
        </li>
        <li className="list-item">
          <a
            href="https://www.instagram.com/justbrandify/"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaInstagramSquare className="icon" />
          </a>
        </li>
        <li className="list-item">
          <a
            href="https://youtube.com/@JustBrandify"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaYoutubeSquare className="icon" />
          </a>
        </li>
        <li className="list-item">
          <a
            href="https://twitter.com/Just_Brandify"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaTwitterSquare className="icon" />
          </a>
        </li>
        <li className="list-item">
          <a
            href="http://tiktok.com/@justbrandifyofficial"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaTiktok className="icon" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMediaLinks;
