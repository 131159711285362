import React, { useEffect } from "react";
import ServiceHero from "./ServiceHero";
import Package from "./Package";

const Services = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <ServiceHero />
      <Package />
    </div>
  );
};

export default Services;
