import React, { useEffect } from "react";
import "./css/calendly.css";

function Calendly() {
  useEffect(() => {
    // This will create a Calendly widget when the component mounts
    const script = document.createElement("script");
    script.src = "https://calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // This will remove the Calendly widget when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/justbrandify/30min"
    />
  );
}

export default Calendly;
