import React, { useEffect, useRef, useState } from "react";
import "./css/bestsellerBrand.css";
import "../css/generalElement.css";
import Form from "./Form";

const BestSellerBrand = () => {
  const sectionRefs = useRef([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("scaleup");
        } else {
          entry.target.classList.remove("scaleup");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    sectionRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const showForm = () => {
    setShow(true);
  };

  const hideForm = (flag) => {
    setShow(flag);
  };

  return (
    <div className="home-bestseller-brand">
      {show && <Form text="BOOK A FREE AUDIT" hide={hideForm} />}
      <div className="bestseller-container">
        <h1>Turn your business into a bestseller brand!</h1>
        <p>
          At JustBrandify, we take a collaborative approach to building your
          brand. With our robust digital strategy, we can help you build a
          stronger, more recognizable brand.
        </p>
        <h2>Our processes</h2>
        <div className="process-points">
          <div className="point">
            <svg
              width="118"
              height="118"
              viewBox="0 0 118 118"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="brand-audit-svg"
              ref={(el) => (sectionRefs.current[0] = el)}
            >
              <circle cx="59" cy="59" r="59" fill="#FFD9D9" />
              <path
                d="M89.3787 27.7534V81.9016H45.1604V79.7675C46.1 79.9666 47.0715 80.0701 48.0748 80.0701C55.7591 80.0701 61.9941 73.843 61.9941 66.1508C61.9941 58.4586 55.767 52.2316 48.0748 52.2316C47.0794 52.2316 46.1 52.3351 45.1604 52.5342V27.7534H89.3787Z"
                fill="url(#paint0_linear_20_5)"
                stroke="url(#paint1_linear_20_5)"
                stroke-width="5"
                stroke-miterlimit="10"
              />
              <path
                d="M54.4611 35.1748H83.9798"
                stroke="#FFD9D9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M50.1213 41.7522H83.9798"
                stroke="#FFD9D9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M50.1213 48.3218H83.9798"
                stroke="#FFD9D9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M57.8215 54.8991C66.5568 54.8513 75.2843 54.8513 83.9798 54.8991"
                stroke="#FFD9D9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M62.5594 61.4766C69.7261 61.5084 76.8211 61.5084 83.9878 61.4766"
                stroke="#FFD9D9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M63.3717 68.054C70.2676 68.054 77.1396 68.054 83.9798 68.054"
                stroke="#FFD9D9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M60.5607 74.6312C68.4122 74.6073 76.1602 74.6073 83.9798 74.6312"
                stroke="#FFD9D9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M61.9941 66.1586C61.9941 73.8429 55.767 80.0779 48.0748 80.0779C47.0794 80.0779 46.1 79.9744 45.1604 79.7753C38.8696 78.4295 34.1555 72.8475 34.1555 66.1586C34.1555 59.4697 38.8696 53.8877 45.1604 52.5499C46.1 52.3508 47.0715 52.2473 48.0748 52.2473C55.7591 52.2394 61.9941 58.4744 61.9941 66.1586Z"
                stroke="url(#paint2_linear_20_5)"
                stroke-width="5"
                stroke-miterlimit="10"
              />
              <path
                d="M38.137 75.8975L34.06 79.9825"
                stroke="url(#paint3_linear_20_5)"
                stroke-width="5"
                stroke-miterlimit="10"
              />
              <path
                d="M21.0167 89.928L31.902 79.0427C32.2763 78.6684 32.8815 78.6684 33.2557 79.0427L34.9996 80.7865C35.3739 81.1608 35.3739 81.766 34.9996 82.1402L24.1142 93.0177C23.74 93.3919 23.1348 93.3919 22.7605 93.0177L21.0167 91.2817C20.6424 90.9075 20.6424 90.2943 21.0167 89.928Z"
                stroke="url(#paint4_linear_20_5)"
                stroke-width="5"
                stroke-miterlimit="10"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_20_5"
                  x1="67.2683"
                  y1="26.3045"
                  x2="67.2683"
                  y2="82.6998"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_20_5"
                  x1="67.2683"
                  y1="27.5583"
                  x2="67.2683"
                  y2="82.1038"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_20_5"
                  x1="41.0151"
                  y1="78.3863"
                  x2="55.1339"
                  y2="53.9319"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_20_5"
                  x1="38.2778"
                  y1="77.94"
                  x2="33.9192"
                  y2="77.94"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_20_5"
                  x1="20.5387"
                  y1="86.0314"
                  x2="35.4752"
                  y2="86.0314"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
              </defs>
            </svg>

            <h3>Brand audit</h3>
            <p>
              We evaluate your brand's current state, identify gaps, and
              recommend revisions to help you leverage the power of your brand
            </p>
          </div>
          <div className="point">
            <svg
              width="118"
              height="118"
              viewBox="0 0 118 118"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              ref={(el) => (sectionRefs.current[1] = el)}
            >
              <circle cx="59" cy="59" r="59" fill="#FFD9D9" />
              <path
                d="M60.575 40.4333L56.0083 63.475L58.125 63.8917L62.6917 40.85L71.4583 66.5334L60.025 86.8084L45.6333 83.95L42.5417 60.8L60.575 40.4333Z"
                fill="url(#paint0_linear_20_6)"
              />
              <path
                d="M58.3833 95.0917L43.9917 92.2333L45.3667 85.3083L59.7583 88.1584L58.3833 95.0917Z"
                fill="url(#paint1_linear_20_6)"
                stroke="url(#paint2_linear_20_6)"
                stroke-miterlimit="10"
              />
              <path
                d="M58.9333 37.2499C50.9 36.1583 42.7583 37.3583 35.45 41.0666C31.0167 43.4583 26.95 46.2166 23.575 49.9666"
                stroke="url(#paint3_linear_20_6)"
                stroke-width="5"
                stroke-miterlimit="10"
              />
              <path
                d="M64.525 37.5501C64.525 37.5501 85.5417 38.2084 96.425 26.9084"
                stroke="url(#paint4_linear_20_6)"
                stroke-width="5"
                stroke-miterlimit="10"
              />
              <path
                d="M61.8 39.8834C63.305 39.8834 64.525 38.6633 64.525 37.1583C64.525 35.6534 63.305 34.4333 61.8 34.4333C60.295 34.4333 59.075 35.6534 59.075 37.1583C59.075 38.6633 60.295 39.8834 61.8 39.8834Z"
                stroke="url(#paint5_linear_20_6)"
                stroke-width="5"
                stroke-miterlimit="10"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_20_6"
                  x1="62.4164"
                  y1="37.1923"
                  x2="52.2929"
                  y2="86.6689"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="6.81957e-07" stop-color="#5928E5" />
                  <stop offset="0.9949" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_20_6"
                  x1="52.5928"
                  y1="86.5844"
                  x2="50.9206"
                  y2="95.0174"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="6.81957e-07" stop-color="#5928E5" />
                  <stop offset="1" stop-color="#F502FD" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_20_6"
                  x1="44.6398"
                  y1="88.763"
                  x2="59.1127"
                  y2="91.6328"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="6.81957e-07" stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_20_6"
                  x1="58.9605"
                  y1="43.3817"
                  x2="23.4184"
                  y2="43.3817"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="6.81957e-07" stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_20_6"
                  x1="96.5775"
                  y1="32.2676"
                  x2="64.5193"
                  y2="32.2676"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="6.81957e-07" stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_20_6"
                  x1="64.7342"
                  y1="37.1559"
                  x2="58.8675"
                  y2="37.1559"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="6.81957e-07" stop-color="#5928E5" />
                  <stop offset="0.9949" stop-color="#AF13F2" />
                </linearGradient>
              </defs>
            </svg>

            <h3>Digital strategy</h3>
            <p>
              We create a formidable digital strategy that promotes your
              business, generates new leads, and nurtures them into loyal
              customers. We continuously monitor and improve your online
              presence to ensure your brand stands out.
            </p>
          </div>
          <div className="point">
            <svg
              width="118"
              height="118"
              viewBox="0 0 118 118"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              ref={(el) => (sectionRefs.current[2] = el)}
            >
              <circle cx="59" cy="59" r="59" fill="#FFD9D9" />
              <path
                d="M89.2 36.5555C89.2 40.1406 86.2889 43.0518 82.7037 43.0518C80.2593 43.0518 78.1259 41.7036 77.0148 39.7036H76.2V48.711C76.2 50.0073 75.1407 51.0592 73.8518 51.0592H63.4222V50.2443C65.4148 49.1332 66.7704 47.0073 66.7704 44.5555C66.7704 40.9703 63.8667 38.0592 60.2741 38.0592C56.6815 38.0592 53.7778 40.9703 53.7778 44.5555C53.7778 46.9999 55.1259 49.1332 57.1259 50.2443V51.0592H46.6963C45.4 51.0592 44.3481 49.9999 44.3481 48.711V24.3999C44.3481 23.1036 45.4074 22.0518 46.6963 22.0518H73.8593C75.1555 22.0518 76.2074 23.111 76.2074 24.3999V33.4073H77.0222C78.1333 31.4147 80.2593 30.0592 82.7037 30.0592C86.2963 30.0592 89.2 32.9703 89.2 36.5555Z"
                fill="url(#paint0_linear_20_7)"
              />
              <path
                d="M60.0741 52.6667C63.6593 52.6667 66.5704 55.5779 66.5704 59.163C66.5704 61.6075 65.2222 63.7408 63.2222 64.8519V65.6667H72.2296C73.5259 65.6667 74.5778 66.726 74.5778 68.0149V78.4519H73.763C72.6519 76.4519 70.5259 75.1038 68.0741 75.1038C64.4889 75.1038 61.5778 78.0075 61.5778 81.6001C61.5778 85.1927 64.4889 88.0964 68.0741 88.0964C70.5185 88.0964 72.6519 86.7482 73.763 84.7482H74.5778V95.1853C74.5778 96.4742 73.5185 97.5334 72.2296 97.5334H47.9259C46.6296 97.5334 45.5778 96.4742 45.5778 95.1853V68.0075C45.5778 66.7112 46.637 65.6593 47.9259 65.6593H56.9333V64.8445C54.9333 63.7334 53.5852 61.6075 53.5852 59.163C53.5852 55.5779 56.4889 52.6667 60.0741 52.6667Z"
                fill="url(#paint1_linear_20_7)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_20_7"
                  x1="66.7737"
                  y1="22.0488"
                  x2="66.7737"
                  y2="51.0592"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="4.04912e-07" stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_20_7"
                  x1="60.0774"
                  y1="52.6675"
                  x2="60.0774"
                  y2="97.5245"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="4.04912e-07" stop-color="#5928E5" />
                  <stop offset="1" stop-color="#AF13F2" />
                </linearGradient>
              </defs>
            </svg>

            <h3>Branding & Design</h3>
            <p>
              Our creatives design visually stunning materials that resonate
              with your audience, leading to faster recall times and repeat
              business.
            </p>
          </div>
        </div>
        <button className="btn-bestseller" onClick={showForm}>
          <h4>BOOK A FREE AUDIT</h4>
        </button>
      </div>
    </div>
  );
};

export default BestSellerBrand;
