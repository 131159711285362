import React, { useEffect, useRef, useState } from "react";
import "./css/upliftBrand.css";
import Form from "./Form";

const UpliftBrand = () => {
  const sectionRefs = useRef([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
        } else {
          entry.target.classList.remove("fade-in");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    sectionRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const showForm = () => {
    setShow(true);
  };

  const hideForm = (flag) => {
    setShow(flag);
  };

  return (
    <div className="home-uplift-brand">
      {show && <Form text="BOOK A STRATEGY SESSION" hide={hideForm} />}
      <div className="uplift-container">
        <h1>Uplift your brand with JustBrandify</h1>
        <p>We Help Companies Solve Their Branding & Marketing Challenges!</p>
        <h2>How it works</h2>
        <div className="work-points">
          <div className="point" ref={(el) => (sectionRefs.current[0] = el)}>
            <h3>1. SESSION</h3>
            <p>
              Partner with us in a strategy session to identify your business
              needs and receive tailored solutions for optimal success.
            </p>
          </div>
          <div className="point" ref={(el) => (sectionRefs.current[1] = el)}>
            <h3>2. ON-BOARDING</h3>
            <p>
              Quick on-boarding with the client with as little as 2 days of lead
              time.
            </p>
          </div>
          <div className="point" ref={(el) => (sectionRefs.current[2] = el)}>
            <h3>3. IDEATE</h3>
            <p>
              With a content strategy based solely on ideas that work, we help
              you channelize your marketing efforts.
            </p>
          </div>
          <div className="point" ref={(el) => (sectionRefs.current[3] = el)}>
            <h3>4. PROCURE</h3>
            <p>
              Infographics, GIFs, video blogs, E-books, articles, newsletters,
              you name it: we create and curate the best content across formats.
            </p>
          </div>
          <div className="point" ref={(el) => (sectionRefs.current[4] = el)}>
            <h3>5. DISTRIBUTE</h3>
            <p>
              With a constantly evolving network of legacy and new age
              publishers and influencers, we help your content reach the people
              for whom it was made.
            </p>
          </div>
          <div className="point" ref={(el) => (sectionRefs.current[5] = el)}>
            <h3>6. GROWTH</h3>
            <p>We start hitting benchmarks set by you, time to grow!</p>
          </div>
        </div>
        <button className="btn-uplift" onClick={showForm}>
          <h4>BOOK A STRATEGY SESSION</h4>
        </button>
      </div>
    </div>
  );
};

export default UpliftBrand;
